@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  font-family: 'Poppins';
  height: 100%;
}

.transition_all {
  transition: all 0.25s;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #29434e !important;
  /* -webkit-box-shadow: 0 0 0 30px #2f4d64 inset !important; */
  caret-color: #d1d5db;
}

.slate {
  position: relative;
  padding-bottom: 20px;
}

.slate::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  width: 100px;
  left: calc(50% - 50px);
  border-top: 4px solid #46f7ad;
}

.bg-2 {
  background-image: url('./assets/chekkit-bg.svg');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 25%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.card {
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}
/*
.circle_item {
  list-style-image: url('assets/circle-item.svg');
}

.dot_item {
  list-style-image: url('assets/dot-item.svg');
} */

.tooltip {
  background-color: white;
  position: relative;
}

.tooltip::after {
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  left: 16px;
  top: 95%;
  border-width: 1rem;
  border-style: solid;
  border-color: white rgba(205, 133, 63, 0) rgba(205, 133, 63, 0) rgba(205, 133, 63, 0);
}
